<template>
  <v-dialog v-model="dialog" fullscreen hide-overlay>
    <template v-slot:activator="{ on, attrs }">
      <v-btn v-bind="attrs" v-on="on" color="secondary" dark icon outlined>
        <v-icon color="secondary">mdi-filter-variant</v-icon>
      </v-btn>
    </template>
    <v-card tile>
      <v-app-bar color="primary" dark>
        <v-btn dark icon @click.native="closeDialog">
          <v-icon>mdi-close</v-icon>
        </v-btn>
        <v-spacer/>
        <v-toolbar-title>
          {{ $vuetify.lang.t('$vuetify.pages.search.sortAndFilter.filter') }}
        </v-toolbar-title>
        <v-spacer/>
      </v-app-bar>
      <v-row justify="center">
        <v-col cols="12" lg="6" md="8" sm="10">
          <kurcc-sort-and-filter/>
        </v-col>
      </v-row>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: 'KurccSortAndFilterDialog',
  components: {
    KurccSortAndFilter: () => import('@/modules/home/components/KurccSortAndFilter')
  },
  data () {
    return {
      dialog: false,
      filterComponentKey: 0
    }
  },
  methods: {
    closeDialog () {
      this.dialog = false
    }
  }
}
</script>
